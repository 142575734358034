import { hasComponent } from "bitecs";
import { HubsWorld } from "../app"
import { HeadCollider, Portal, Rigidbody } from "../bit-components"
import { changeHub } from "../change-hub";
import { anyEntityWith } from "../utils/bit-utils"

let isWarping = false;

export function portalSystem(world: HubsWorld, physicsSystem: any) {

    const head = anyEntityWith(world, HeadCollider);

    const collisions = physicsSystem.getCollisions(Rigidbody.bodyId[head]);
    for (let i = 0; i < collisions.length; i++) {
        const bodyData = physicsSystem.bodyUuidToData.get(collisions[i]);
        const collidedEid = bodyData && bodyData.object3D && bodyData.object3D.eid;

        if (hasComponent(world, Portal, collidedEid)) {
            isWarping = true;

            changeHub(APP.getString(Portal.roomId[collidedEid]), true);
            isWarping = false;
        }
    }

}