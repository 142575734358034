// =========================================================
// 	Component: iot-sensor
//	Description: MQTT client to read and write IoT data
// 	for 3D Visualization and Representation purposes.
//
//	Team: 
//	- Leonardo Azzi Martins (martinsl@db.tec.br)
//	- Felipe Vargas (felipev@db.tec.br)
//	- José Rodolfo Masiero (joser@db.tec.br)
//
// =========================================================

import * as prot_data from './iotData.json';

const paho = require('paho-mqtt');

// Cliente MQTT
const clientAddr = "emqx.dbserver.com.br";
const clientPort = 443;
const client = new paho.Client(clientAddr, clientPort, new Date().toString())

// Cores de classificação
const aframeColors = {
	default: "white",
	excellent: "green",
	good: "green",
	ok: "green",
	notice: "yellow",
	warning: "orange",
	bad: "red"
}



AFRAME.registerComponent('iot-sensor', {
	schema: {
		PROTOTYPE: { type: 'bool', default: false },	// Para design

	},

	init: ()=>{
		console.log("iot-sensors: componente iniciado.")

 	},

    update: function () {

		const options = {
			useSSL: true,
			timeout: 3,
			userName: "dbdevices",
			password: "bnj4i9MirzHy2f",
			onSuccess: onConnect

		}

		if(this.data.PROTOTYPE) {	// Para design

			console.log("iot-sensors: modo de protótipo, não conectado no MQTT.")

			updateDashboard(prot_data.default);
			updateLamp(prot_data.default);
			updateHumidity(prot_data.default);

		} else {
			console.log("iot-sensors: modo de mensagens MQTT.")
			client.onConnectionLost = onConnectionLost;
			client.onMessageArrived = onMessageArrived;
			client.connect(options)

		}


		function onConnect() {
			client.subscribe("dblab/mqtt/dblab/Vroom-01/status");
			console.log("iot-sensors: Conectado em ", new Date());
		}
		
		function onConnectionLost(responseObject) {
			if (responseObject.errorCode !== 0) {
				console.log("onConnectionLost:" + responseObject.errorMessage);
			}
		}
		
		function onMessageArrived(message) {
			var data = JSON.parse(message.payloadString.toString())[0];
		
			updateDashboard(data);
			updateLamp(data);
			updateHumidity(data);
			
			return data;
		
		}

		// ---------------------------------------------------------
		// 	DASHBOARD
		//	(Humidity, TVOC, CO2, Pressure, Temperature, Altitude)
		// ---------------------------------------------------------
		function updateDashboard(data){
			setText(data.Environment.Humidity.toFixed(2), 'humidity', '%');
			setText(data.Environment.TVOC, 'tvoc', 'ppb');
			setText(data.Environment.CO2, 'co2', 'ppm');
			setText(data.Environment.Pressure / 1000,'pressure','kPa');
			setText(data.Environment.Temperature,'temperature','C');
			setText(data.Environment.Altitude.toFixed(2),'altitude','m');     
			setText(showTime(),'date','')
			// setText(showSplitStatus(data.Split.Status), 'split', '')      
			
		}
		
		// ---------------------------------------------------------
		// 	LAMP
		//	(On/Off)
		// ---------------------------------------------------------
		function updateLamp(data){
			setStatus(data.LightSpot.Status, 'lab');

		}

		// ---------------------------------------------------------
		// 	HUMIDITY
		//	(% Relative Humidity)
		// ---------------------------------------------------------
		
		function updateHumidity(data){
			const partMAX = 500;
			let relHumidity = data.Environment.Humidity.toFixed(0);
			let numPart = (relHumidity * partMAX) / 100;

			console.log("Umidade Relativa: " + relHumidity);
			console.log("Número de partículas: " + numPart);

			setParticleEmitter(numPart, "particle");
			setText(relHumidity,'particle','%RH');

			// Vermelho
			if(relHumidity >= 0 && relHumidity <= 24){
				setTextColor('particle', aframeColors.bad);

			// Laranja
			} else if (relHumidity >= 25 && relHumidity <=34){
				setTextColor('particle', aframeColors.warning);

			// Amarelo
			} else if (relHumidity >= 35 && relHumidity <=44){
				setTextColor('particle', aframeColors.notice);

			// Verde Claro
			} else if (relHumidity >= 45 && relHumidity <=50){
				setTextColor('particle', aframeColors.ok);
				
			// Verde
			} else if (relHumidity >= 51 && relHumidity <= 56){
				setTextColor('particle', aframeColors.good);

			// Verde Escuro
			} else if (relHumidity >= 57 && relHumidity <= 63){
				setTextColor('particle', aframeColors.excellent);
			
			// Verde
			} else if (relHumidity >= 64 && relHumidity <= 70){
				setTextColor('particle', aframeColors.good);

			// Verde Claro
			} else if (relHumidity >= 71 && relHumidity <= 76){
				setTextColor('particle', aframeColors.ok);

			// Amarelo
			} else if (relHumidity >= 77 && relHumidity <= 82){
				setTextColor('particle', aframeColors.notice);

			// Laranja
			} else if (relHumidity >= 83 && relHumidity <= 88){
				setTextColor('particle', aframeColors.warning);

			// Vermelho
			} else if (relHumidity >= 89 && relHumidity <= 100){
				setTextColor('particle', aframeColors.bad);
			
			// Branco
			} else {
				setTextColor('particle', aframeColors.default);
			}

		}

		// =========================================================
		// 	ACTION FUNCTIONS
		// =========================================================
		// ---------------------------------------------------------
		// 	TEXT
		// ---------------------------------------------------------
		function setText(currentValue, id, suffix, color) {
			const txt = document.getElementById(`${id}_value`)

			txt.setAttribute('text', { value: `${currentValue} ${suffix}` })
			txt.setAttribute('text', { color: `${color}` })
			
		}

		function setTextColor(id, color){
			const txt = document.getElementById(`${id}_value`)
			txt.setAttribute('text', { color: `${color}` })

		}

		// ---------------------------------------------------------
		// 	DATE/HOUR
		// ---------------------------------------------------------
		function showTime(){
			const date = new Date();
			const hours = date.getHours(); 
			const minutes = date.getMinutes();

			const dataAtual = `${hours}:${minutes}`;

			return dataAtual;
			
		}

		// ---------------------------------------------------------
		// 	LIGHT ON-OFF
		// ---------------------------------------------------------
		function setStatus(currentValue, id) {
			//Lampada
			if(currentValue === true){
				document.getElementById(id+"-status").setAttribute('spot-light', { intensity: 10.0 })
			} else {
				document.getElementById(id+"-status").setAttribute('spot-light', { intensity: 0.0 })
			}

		}

		// ---------------------------------------------------------
		// 	PARTICLE EMITTER
		// ---------------------------------------------------------
		function setParticleEmitter(nPart, id){
			let humidityEl = document.getElementById(id+"-humidity");

			humidityEl.setAttribute('particle-emitter', { particleCount: nPart,
															startSize: 0.25000000000000009,
															endSize: 0.25000000000000009,
															sizeRandomness: 0,
															sizeCurve: "cubicIn",
															lifetime: 2.0,
															lifetimeRandomness: 1.0,
															startVelocity: { x: 0, y: 0, z: 0.10000000000000009 },
															endVelocity: { x: 0, y: 0, z: 0.10 },
															ageRandomness: 2,
															startColor: "#4a90e2",
															middleColor: "#4a90e2",
															endColor: "#8ab7eb",
														});
		}

        
    }

});

