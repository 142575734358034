import { HubsWorld } from "../app";
import { addComponent } from "bitecs";
import { Pinned, Portal, Rigidbody } from "../bit-components";
import { COLLISION_LAYERS } from "../constants";
import { RIGIDBODY_FLAGS } from "../systems/bit-physics";
import { PhysicsShape } from "../bit-components";


export function inflatePortal(world: HubsWorld, eid: number, { roomId }: { roomId: string }) {
  addComponent(world, Portal, eid);
  Portal.roomId[eid] = APP.getSid(roomId)!;

  // Make a rigidbody
  addComponent(world, Rigidbody, eid);

  Rigidbody.collisionGroup[eid] = COLLISION_LAYERS.PORTALS; // What is going to be collidable
  Rigidbody.collisionMask[eid] = COLLISION_LAYERS.AVATAR; //  What is going to be collided with
  Rigidbody.flags[eid] = RIGIDBODY_FLAGS.DISABLE_COLLISIONS;

  // Give rigidbody a shape
  addComponent(world, PhysicsShape, eid);
  PhysicsShape.halfExtents[eid].set([0.25, 1, 0.25]);

  addComponent(world, Pinned, eid);
}
