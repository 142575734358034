import { addComponent } from "bitecs";
import {    Dbot, 
            NetworkedDbot,
            CursorRaycastable,  
            RemoteHoverTarget, 
            SingleActionButton, 
            Networked  
        } from "../bit-components"

export function inflateDbot(world, eid, { isTalking }) {
    console.log("Inflating a dbot component", { eid, isTalking });
    addComponent(world, Dbot, eid);
    addComponent(world, Networked, eid);
    addComponent(world, NetworkedDbot, eid);
    Dbot.isTalking[eid] = isTalking;
    NetworkedDbot.isTalking[eid] = isTalking;

    addComponent(world, RemoteHoverTarget, eid);
    addComponent(world, CursorRaycastable, eid);
    addComponent(world, SingleActionButton, eid);
}