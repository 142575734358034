

/*
 * DESCRICAO
 */

AFRAME.registerComponent('covid-vis', {
	schema: {

	},

	init: function()
	{
        this.time = 0;
		this.clicktime = 0;
		this.available = 1;
		this.was_available = 0;
		this.pos = new THREE.Vector3();
		this.onClick = this.onClick.bind(this);
        this.onClick = this.onClick.bind(this);
		this.el.object3D.addEventListener('interact', this.onClick);

        console.log("covid data-vis init!")
        
	},

    onClick: function(evt)
	{
		console.log("Inicio");
        window.APP.hubChannel.sendMessage("Iniciando contaminação exponencial de base 2 (2^n)", "chat");
        this.start();
    },

    start: function() {

        const sleepNow = (delay) => new Promise((resolve) => setTimeout(resolve, delay))
        const avatCovid = "https://db-vroom.com/api/v1/avatars/3mZRAL5/avatar.gltf?v=63804564445"
        const players = document.querySelectorAll("a-entity[player-info]")

        function contamina(exp, saudaveis) {
            let indexCovid = [];

            let indexSaudaveis = saudaveis;

            const numContaminar = Math.pow(2, exp);
            console.log("O Covid vai contaminar" + numContaminar)

            window.APP.hubChannel.sendMessage(exp + " contamina "+numContaminar, "chat");
            window.APP.hubChannel.sendMessage("Base: 2; Expoente: "+exp, "chat");

            for(let i=0; i<numContaminar && indexSaudaveis.length>0; i++){
                const randomIndex = indexSaudaveis[Math.floor(Math.random()*indexSaudaveis.length)];
                const playerSelecionado = players[randomIndex-1] // Lista de players começa em 0

                console.log("Player contaminado: ", randomIndex)

                playerSelecionado.setAttribute("player-info", "avatarSrc: " + avatCovid);
                NAF.utils.getNetworkedEntity(playerSelecionado).then(data=>{
                    data.setAttribute("player-info", "avatarSrc: " + "https://db-vroom.com/api/v1/avatars/3mZRAL5/avatar.gltf?v=63804564445");
                }); 
                
                indexCovid.push(randomIndex)

                // Remove da lista de saudáveis os contaminados.
                indexSaudaveis = indexSaudaveis.filter(function (item) {
                    return indexCovid.indexOf(item) === -1;
                });
                console.log("saudaveis "+indexSaudaveis)
                console.log("contaminados "+indexCovid)
            }

            return [indexCovid, indexSaudaveis]
        }

        async function dataVis() {

            let listaCovid = [];
            let listaSaudaveis = [];
            let indexCovid = [];
            let indexSaudaveis = [];

            const numPessoas = players.length;

            // Cria lista de participantes
            for (let i=1; i<=numPessoas; i++){
                listaSaudaveis.push(i)
            }

            let numContaminados = 0;
            let numSaudaveis = numPessoas;


            for (let i=0; numSaudaveis>0; i++){
                await sleepNow(10000);
    
                // Realiza a contaminação por expoente
                let [indexCovid, indexSaudaveis] = contamina(i, listaSaudaveis);
                
                console.log("index covid", indexCovid)
                Array.prototype.push.apply(listaCovid, indexCovid);
                console.log("lista covid", listaCovid)
                listaSaudaveis = indexSaudaveis;

                numContaminados = listaCovid.length;
                numSaudaveis = listaSaudaveis.length;
                window.APP.hubChannel.sendMessage("NÚMERO DE CONTAMINADOS: "+numContaminados, "chat");
                
                console.log("Número de saudáveis: " + numSaudaveis);
                console.log("Número de contaminados: " + numContaminados);
            }
            console.log("FIM!");
            window.APP.hubChannel.sendMessage("100% DE CONTAMINAÇÃO", "chat");

        };

        dataVis();
    }

});