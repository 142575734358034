import axios from 'axios'

export const httpClientRecognition = axios.create({
  baseURL:
    process.env.REACT_APP_API_RECOGNITION_API || 'https://stt-api.paas.dbserver.com.br',
})

export const httpClientRasa = axios.create({
  baseURL:
    process.env.REACT_APP_RASA_API ||
    'https://dbot-api.dblab.click/webhooks/rest',
})

export const headers = {
  headers: {
    'Content-Type': 'application/json',
  },
}
