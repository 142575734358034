import { headers } from '../infra/httpClients'
import BaseService from './base.service'

export default class RasaService extends BaseService {
  async createRecognitionRasa(transcription) {
    try {
      const response = (
        await this.api.post(
          '/webhook',
          { sender: 'demo', message: transcription },
          headers,
        )
      ).data
      return response[0]
    } catch (error) {
      throw RasaService.handleError(error)
    }
  }
}
