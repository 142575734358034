import { addComponent } from "bitecs";
import {    Door, 
            NetworkedDoor,
            CursorRaycastable,  
            RemoteHoverTarget, 
            SingleActionButton, 
            Networked  
        } from "../bit-components"

export function inflateDoor(world, eid, { isOpen }) {
    console.log("Inflating a door component", { eid, isOpen });
    addComponent(world, Door, eid);
    addComponent(world, Networked, eid);
    addComponent(world, NetworkedDoor, eid);
    Door.isOpen[eid] = isOpen;
    NetworkedDoor.isOpen[eid] = isOpen;

    addComponent(world, RemoteHoverTarget, eid);
    addComponent(world, CursorRaycastable, eid);
    addComponent(world, SingleActionButton, eid);
}