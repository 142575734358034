import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ReactComponent as Radio } from "../icons/Radio.svg";
import RecordRTC, { StereoAudioRecorder } from "recordrtc";
import { ReactComponent as RadioRed } from "../icons/RadioRed.svg";
import { ToolbarButton } from "./ToolbarButton";
import RecognitionService from "../../recognition/services/recognition.service";
import RasaService from "../../recognition/services/rasa.service";
import { httpClientRasa, httpClientRecognition } from "../../recognition/infra/httpClients";
import { mergeLeftRightBuffers } from "../../utils/merge-left-right-buffers";

import { throwErrorDbot } from "../../bit-systems/dbotSystem";
import { emitMessageDbot } from "../../dbot/dbot.service";

export const BotButton = () => {
  const [recording, setRecording] = useState(false);
  const [stream, setStream] = useState(null);
  const [blob, setBlob] = useState(null);
  const recorderRef = useRef(null);
  const [responseRasa, setResponseRasa] = useState("");
  const [recognition, setRecognition] = useState("");
  const recognitionService = new RecognitionService(httpClientRecognition);
  const rasaService = new RasaService(httpClientRasa);

  const handleRecorder = async recorderState => {
    setRecording(recorderState);
    console.log("recorderState", recorderState);
    recorderState ? await handleStart() : await handleStop();
  };

  const handleStart = async () => {
    const mediaStream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
    setStream(mediaStream);
    recorderRef.current = new RecordRTC(mediaStream, { type: "audio", recorderType: StereoAudioRecorder });
    if (recorderRef.current) recorderRef.current.startRecording();
  };

  const handleStop = async () => {
    try {
      let blobWave;
      recorderRef.current.stopRecording(() => {
        setBlob(recorderRef.current.getBlob());
      });
      const internalRecorder = recorderRef.current.getInternalRecorder();
      const leftchannel = internalRecorder.leftchannel;
      const rightchannel = internalRecorder.rightchannel;

      const bufferMerge = await mergeLeftRightBuffers({
        desiredSampRate: internalRecorder.desiredSampRate,
        sampleRate: internalRecorder.sampleRate,
        numberOfAudioChannels: internalRecorder.numberOfAudioChannels,
        internalInterleavedLength: internalRecorder.recordingLength,
        leftBuffers: leftchannel,
        rightBuffers: internalRecorder.numberOfAudioChannels === 1 ? [] : rightchannel
      });

      blobWave = new Blob([bufferMerge.buffer], {
        type: "audio/wav"
      });

      const recognitionResponse = await recognitionService.createRecognition(blobWave);
      console.log(recognitionResponse, "Audio reconhecido");
      setRecognition(recognitionResponse.message);
      emitMessageDbot({ message: recognitionResponse.message });
    } catch (error) {
      throwErrorDbot("Ops, não consegui entender o que você falou. Poderia tentar novamente?");
    }
  };

  useEffect(() => {
    if (!recorderRef.current) {
      return;
    }
  }, [stream, recorderRef]);

  // DEV: Exibe apenas no ambiente de desenvolvimento
  if (process.env.NODE_ENV === "development") {
    return (
      <ToolbarButton
        icon={recording ? <RadioRed /> : <Radio />}
        preset="basic"
        label={<FormattedMessage id={"toolbar.command"} defaultMessage="Hey DBot!" />}
        onClick={async () => await handleRecorder(!recording)}
      />
    );
  } else {
    return null;
  }
};
