
import { headers } from '../infra/httpClients'
import BaseService from './base.service'

export default class RecognitionService extends BaseService {
  async createRecognition(blob) {
    console.log('RecognitionService');
    try {
      let file = new File([blob], 'name.wav', { type: 'audio/wave' })
      const formData = new FormData()
      formData.append('file', file)
      const response = (
        await this.api.post(
          '/recognition',
          formData,
          headers,
        )
      ).data;
      return response;
    } catch (error) {
      throw RecognitionService.handleError(error)
    }
  }
}
