import { paths } from "../systems/userinput/paths";
import { getAnalyser } from "../components/audio-feedback";
import { SOUND_SPECIAL_QUACK } from "../systems/sound-effects-system";


const isMobile = AFRAME.utils.device.isMobile();
// const isMobileVR = AFRAME.utils.device.isMobileVR();

export function isLooking(userinput) {
  const cameraDelta = userinput.get(
    isMobile ? paths.device.touchscreen.touchCameraDelta : paths.device.smartMouse.cameraDelta
  );

  const turnAction = userinput.get(paths.actions.snapRotateLeft) || userinput.get(paths.actions.snapRotateRight);

  if (cameraDelta || turnAction) {
    return true;
  } else {
    return false;
  }
}

export function isWalking(userinput) {
  const accel = userinput.get(paths.actions.characterAcceleration);

  if (accel && (accel[0] !== 0 || accel[1] !== 0)) {
    return true;
  } else {
    return false;
  }
}

export function isTalking() {
  const head = document.querySelector(".Head");
  const audio = getAnalyser(head);

  if (audio.volume > 0 && audio.volume < 1.0) {
    return true;
  } else {
    return false;
  }
}

export function emitSound() {
  const sceneEl = document.querySelector("a-scene");
  sceneEl.systems["hubs-systems"].soundEffectsSystem.playSoundOneShot(SOUND_SPECIAL_QUACK);

  return true;
}

export function easterEgg(text) {
  const sceneEl = document.querySelector("a-scene");
  const el = document.createElement("a-entity");

  if (text.includes("pingolinha")) {
    this.addToPresenceLog({ type: "chat", body: "🐓 Pingolinha" + ": cócócó." });
    el.setAttribute("media-loader", {
      src: "https://sketchfab.com/3d-models/2017-year-of-the-rooster-b811f79494b048cbb91b17a7387b0dce",
      fitToBox: true,
      resolve: true
    });
    el.setAttribute("position", "0 0.0 0.5");
    el.setAttribute("rotation", "0 90 0");
    el.setAttribute("id", "pingolinha");
    sceneEl.appendChild(el);
  }
}

export function getPlayerDistance() {
  const playerPos = document.querySelector("#avatar-rig").object3D.position;

  const botPos = document.querySelector("[smartnpc]").object3D.position;

  const distance = playerPos.distanceTo(botPos);

  return distance;
}

export async function isMicMute() {
  let playerInfo = document.querySelectorAll("[player-info]");
  playerInfo = playerInfo[0].getAttribute("player-info");

  return playerInfo.muted;
}

