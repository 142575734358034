/**
 * dbotSystem.js
 * Non-Playable Character (NPC), powered by
 * a Rasa chatbot flow via WebSocket.
 *
 * @author Leonardo Azzi Martins
 * @author Pedro H. S. Mietlicki
 */

import { Dbot, Interacted, NetworkedDbot } from "../bit-components";
import { defineQuery, enterQuery, exitQuery, hasComponent } from "bitecs";
import { findAncestor } from "../utils/three-utils";
import { takeOwnership } from "../systems/netcode";
import {
  isLooking,
  isWalking,
  isTalking,
  emitSound,
} from "../utils/state-utils";
import { messageListener, emitMessageDbot } from "../dbot/dbot.service";

const animationState = new Map();
const userState = new Map();

let walkState;
let lookState;
let talkState;
let listenEmitState = false;

let mixer,
  action,
  animations = null;

let timer_i = 0;
let timer_delta = 0;

const dbotQuery = defineQuery([Dbot]);
const dbotEnterQuery = enterQuery(dbotQuery);
const dbotExitQuery = exitQuery(dbotQuery);

const responseBuffer = [];
let response = {};
let responseDuration;

/** Caracter to milisecond conversion factor.
 * @type {number}
 */
const TIME_RESP = 100.0;

/**
 * Checks if the entity should be interactable
 * @param {number} eid - The entity id
 * @returns {boolean} Contains the `Interacted` component
 */
function isInteractable(eid) {
  return hasComponent(APP.world, Interacted, eid);
}

/**
 * Looks for the entity ancestor that has animation properties and map them.
 * @param {HubsWorld} world - The world object.
 * @param {number} eid - The entity ID of the entity to check.
 * @returns {boolean} A boolean value.
 */
function hasAnimation(world, eid) {
  /* Looks for the first ancestor of the entity that has a `mixer` property. */
  const animationAncestor = findAncestor(
    world.eid2obj.get(eid),
    (obj) => obj.mixer
  );

  if (animationAncestor !== null) {
    /* Setup mixer and animations from the object. */
    ({ mixer, animations } = animationAncestor);

    return true;
  } else {
    return false;
  }
}

/**
 * Checks if the entity contains an animation and plays it.
 * @param {number} eid - The entity id of the entity that is being animated.
 */
function animate(eid) {
  const { action } = animationState.get(eid);

  if (action !== undefined) {
    Dbot.isTalking[eid] = NetworkedDbot.isTalking[eid];
    action.setEffectiveTimeScale(Dbot.isTalking[eid] ? 1 : -1); // Plays the animation forward or backwards
    action.paused = false;
    action.play();
  }
}

/**
 * Shows an error message in the DBot's dialogue.
 * @param message - The message that the bot will send to the user.
 */
export function throwErrorDbot(errorMessage) {
  const message = new CustomEvent("bot_message", { detail: errorMessage });
  window.dispatchEvent(message);
}

/**
 * DBot Main System
 * @param {HubsWorld} world - Main set of entities and components.
 * @author Leonardo Azzi Martins
 */
export function dbotSystem(world) {
  /* Setup */
  dbotEnterQuery(world).forEach(function (eid) {
    console.log("There exists a new Dbot!", eid);

    if (hasAnimation(world, eid)) {
      action = mixer.clipAction(animations[1]); // Assume the 0th animation is the one we want
      action.clampWhenFinished = true;
      action.loop = THREE.LoopOnce;

      animationState.set(eid, { mixer, action });
    }
  });

  /* Loop */
  dbotQuery(world).forEach(function (eid) {
    if (isInteractable(eid)) {
      console.log("The Dbot was clicked!");
      takeOwnership(world, eid);

      emitMessageDbot({ message: "/dbot preciso de ajuda" });
      emitMessageDbot({ message: "/dbot do zero" });

      NetworkedDbot.isTalking[eid] = !NetworkedDbot.isTalking[eid];

      timer_i = world.time.elapsed;
    }

    /** Bot Answer Buffer */
    if (responseBuffer.length > 0) {
      /** Message without action */
      if (
        response.hasOwnProperty("text") &&
        !response.hasOwnProperty("action")
      ) {
        timer_delta = world.time.elapsed - timer_i;
        responseDuration = response.text.length * TIME_RESP;
        console.log(responseDuration);

        if (timer_delta > responseDuration) {
          response = responseBuffer.shift(); // Takes a new message from the buffer

          timer_i = world.time.elapsed; // Init time
          timer_delta = 0; // Resets the message timer
        }

        /** Message with action */
      } else {
        response = responseBuffer.shift(); // Takes a new message from the buffer
      }

      if (hasAnimation(world, eid)) {
        animate(eid);
      }

      /** Text processing */
      const stringResponse = JSON.stringify(response.text);
      const message = stringResponse.replace(/"/g, "");

      /** Fires message container event */
      const botMessage = new CustomEvent("bot_message", { detail: message });
      window.dispatchEvent(botMessage);
      timer_delta = 0; // Resets the message timer
    }

    /** Gets the userinput object */
    const sceneEl = document.querySelector("a-scene");
    const userinput = sceneEl.systems.userinput;

    if (response !== undefined && response !== null) {
      // Checks message validity

      /** Processing actions */
      if (response.hasOwnProperty("action")) {
        switch (response.action) {
          case "walk":
            walkState = isWalking(userinput);
            userState.set("walk", walkState);
            if (walkState == true) {
              emitMessageDbot({ message: "/affirm" });
              userState.delete("walk");
              response.action = "default";
            }

            break;

          case "look":
            lookState = isLooking(userinput);
            userState.set("look", lookState);

            if (lookState == true) {
              emitMessageDbot({ message: "/affirm" });
              userState.delete("look");
              response.action = "default";
            }

            break;

          case "talk":
            talkState = isTalking(userinput);
            userState.set("talk", talkState);

            if (talkState == true) {
              emitMessageDbot({ message: "/affirm" });
              userState.delete("talk");
              response.action = "default";
            }

            break;

          case "listen":
            if (!listenEmitState) {
              listenEmitState = emitSound();
            }

            userState.set("listenSound", listenEmitState);

            break;

          case "default":
            break;
        }
      }
    }

    /** Updates the animation frame */
    if (hasAnimation(world, eid) && mixer !== undefined) {
      ({ mixer } = animationState.get(eid));
      mixer.update(world.time.delta / 1000);
    }
  });

  /** Delete */
  dbotExitQuery(world).forEach(function (eid) {
    console.log("A Dbot was removed from the world!", eid);

    if (hasAnimation(world, eid)) {
      const { mixer, action } = animationState.get(eid);
      action.stop();
      mixer.uncacheAction(action);
      animationState.delete(eid);
    }
  });
}

/** Gets the DBot answers */
messageListener((data) => {
  if (data !== undefined && data !== null) {
    // Checks validity
    responseBuffer.push(data); // Push to the message buffer
  }
});
