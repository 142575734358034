import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./TipOnboarding.scss";

export function TipOnboarding({ className, children, onDismiss, dismissLabel, image, ...rest }) {

  return (
    <div className={classNames(styles.tip, className)} {...rest}>
      <div className={styles.content}>
        <img src={image}  className={styles.image}/>
      </div>
      {onDismiss && (
        <button className={styles.dismissButton} onClick={onDismiss}>
          {dismissLabel}
        </button>
      )}
    </div>
  );
}

TipOnboarding.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onDismiss: PropTypes.func,
  dismissLabel: PropTypes.node,
  image: PropTypes.image
};
