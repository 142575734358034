import classNames from "classnames";
import React, { useEffect, useState } from "react";
import styles from "./BotContainer.scss";
export default function BotContainer() {
  const [message, setMessage] = useState([]);

  useEffect(() => {
    window.addEventListener("bot_message", ({ detail }) => {
      setMessage(detail);
    });
  }, []);
  
  // DEV: Exibe apenas no ambiente de desenvolvimento
  if(process.env.NODE_ENV === 'development') {
  return message != "" ? (
    <div className={classNames(styles.botContainer)}>
      <div>
        <p>{message}</p> <br />
      </div>
    </div>
  ) : null;
  } else {
    return null;
  }
}
