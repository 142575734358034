import { Door, Interacted, NetworkedDoor} from "../bit-components";
import { defineQuery, enterQuery, exitQuery, hasComponent } from "bitecs";
import { findAncestor } from "../utils/three-utils";
import { takeOwnership } from "../systems/netcode";

function clicked(eid){
    return hasComponent(APP.world, Interacted, eid);

}

const state = new Map();
const doorQuery = defineQuery([Door]);
const doorEnterQuery = enterQuery(doorQuery);
const doorExitQuery = exitQuery(doorQuery);

export function doorSystem(world){
    doorEnterQuery(world).forEach(function (eid) {
        console.log("There exists a new door!", eid);

        const { mixer, animations } = findAncestor(world. eid2obj.get(eid), obj => obj.mixer); // Assume the animated model is in this hierarchy
        const action = mixer.clipAction(animations[0]); // Assume the 0th animation is the one we want
        action.clampWhenFinished = true;
        action.loop = THREE.LoopOnce;
        state.set(eid, { mixer, action });

    });

 
    doorExitQuery(world).forEach(function (eid) {
        console.log("A door was removed from the world!", eid);

        const { mixer, action } = state.get(eid);
        action.stop();
        mixer.uncacheAction(action);
        state.delete(eid);
    
    });

    doorQuery(world).forEach(function (eid) {
        const { mixer, action } = state.get(eid);

        if(clicked(eid)){
            console.log("The door was clicked!")
            takeOwnership(world, eid)
            NetworkedDoor.isOpen[eid] = !NetworkedDoor.isOpen[eid];

        }
        
        // Sync with the networked state
        if(NetworkedDoor.isOpen[eid] !== Door.isOpen[eid]){
            Door.isOpen[eid] = NetworkedDoor.isOpen[eid];
            action.setEffectiveTimeScale(Door.isOpen[eid] ? 1 : -1); // Plays the animation forward or backwards
            action.paused = false;
            action.play();
        }
    
        mixer.update(world.time.delta / 1000);

    });

    1
}