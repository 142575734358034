/**
 *  Dbot service
 *
 * @author Pedro H. S. Mietlicki
 *
 */

import { io } from "socket.io-client";

/**
 * @todo Put the uri in a .env file
 */
const socket = io("https://dbot-api.dblab.click/");


/**
 * Generate ID for DBot Session when user connect for first time
 */
socket.on("connect", () => {
  if (localStorage.getItem("dbot_id")) {
    const session_id = localStorage.getItem("dbot_id");
    socket.emit("session_request", { session_id });
  } else {
    localStorage.setItem("dbot_id", socket.id);
  }
});


/**
 * Sends user messages to DBot's back-end via WebSocket.
 * @param {{ message:string }} data - Object that contains the user messages for DBot
 */
const emitMessageDbot = data => {
  socket.emit("user_uttered", data);
};

/**
 * Listen to all messages sent by Dbot
 * @param {(data:{text:string}) => void} callback
 * @returns {void}
 */
const messageListener = callback => socket.on("bot_uttered", callback);

export { messageListener, emitMessageDbot };
