import { AxiosError } from 'axios'

export default class BaseService {
  api

  constructor(api ) {
    this.api = api
  }

  static handleError(error) {
    const DefaultMessage = 'Oops, aconteceu algo.';

    if (error instanceof AxiosError) {
      const responseError = error.response?.data;
      console.log('responseError', responseError)
      switch (responseError.statusCode) {
        case 500:
          return new Error('Conexão Indisponivel.');
        case 404:
          return new Error(responseError.error);

        default:
          return new Error(DefaultMessage);
      }
    }
    return new Error(DefaultMessage);
  }
}
