import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { Tip } from "./Tip";
import { TipOnboarding } from "./TipOnboarding";
import { useEffect } from "react";
import { discordBridgesForPresences, hasEmbedPresences } from "../../utils/phoenix-utils";
import configs from "../../utils/configs";
import step1Desktop from "../../assets/images/onbording/step1Desktop.png";
import step2Desktop from "../../assets/images/onbording/step2Desktop.png";
import step3Desktop from "../../assets/images/onbording/step3Desktop.png";
import step4Desktop from "../../assets/images/onbording/step4Desktop.png";

// These keys are hardcoded in the input system to be based on the physical location on the keyboard rather than character
let moveKeys = "W A S D";
let turnLeftKey = "Q";
let turnRightKey = "E";

// TODO The API to map from physical key to character is experimental. Depending on prospects of this getting wider
// implimentation we may want to cook up our own polyfill based on observing key inputs
if (window.navigator.keyboard !== undefined && window.navigator.keyboard.getLayoutMap) {
  window.navigator.keyboard
    .getLayoutMap()
    .then(function(map) {
      moveKeys = `${map.get("KeyW") || "W"} ${map.get("KeyA") || "A"} ${map.get("KeyS") || "S"} ${map.get("KeyD") ||
        "D"}`.toUpperCase();
      turnLeftKey = map.get("KeyQ")?.toUpperCase();
      turnRightKey = map.get("KeyE")?.toUpperCase();
    })
    .catch(function(e) {
      // This occurs on Chrome 93 when the Hubs page is in an iframe
      console.warn(`Unable to remap keyboard: ${e}`);
    });
}

const onboardingMessages = defineMessages({
  "tips.mobile.look": {
    id: "tips.mobile.look",
    defaultMessage: "Boas-vindas! 👋 Arraste na tela para olhar em volta 👆."
  },
  "tips.mobile.locomotion": {
    id: "tips.mobile.locomotion",
    defaultMessage: "Perfeito! Para mover-se, faça com os dedos um movimento de pinça 🤏."
  },
  "tips.mobile.invite": {
    id: "tips.mobile.invite",
    defaultMessage: "Use o botão Compartilhar no canto inferior esquerdo para convidar mais pessoas!"
  },
  "tips.desktop.look": {
    id: "tips.desktop.look",
    defaultMessage:
      "Boas vindas à {appName}! 👋 Vamos ver juntos o básico. Clique na tela e arraste para olhar em sua volta. 👀 🖱️"
  },
  "tips.desktop.locomotion": {
    id: "tips.desktop.locomotion",
    defaultMessage: "⌨ Use as teclas {moveKeys} para mover 🚶. Segure SHIFT para andar rápido 🏃."
  },
  "tips.desktop.turning": {
    id: "tips.desktop.turning",
    defaultMessage: "Excelente. ⌨ Use as teclas {turnLeftKey} e {turnRightKey} para girar. ♻️"
  },
  "tips.desktop.invite": {
    id: "tips.desktop.invite",
    defaultMessage: "Ninguém está aqui ainda. Compartilhe o link da sala!"
  }
});

function OkDismissLabel() {
  return <FormattedMessage id="tips.dismiss.ok" defaultMessage="Ok" />;
}

function SkipDismissLabel() {
  return <FormattedMessage id="tips.dismiss.skip" defaultMessage="Pular" />;
}

export function FullscreenTip(props) {
  return (
    <Tip {...props} dismissLabel={<OkDismissLabel />}>
      <FormattedMessage
        id="tips.fullscreen"
        defaultMessage="Modo tela cheia. Pressione Esc para mostrar a interface."
      />
    </Tip>
  );
}

export function TipContainer({ hide, inLobby, inRoom, isStreaming, isEmbedded, scene, store, hubId, presences }) {
  const intl = useIntl();
  const [lobbyTipDismissed, setLobbyTipDismissed] = useState(false);
  const [broadcastTipDismissed, setBroadcastTipDismissed] = useState(() =>
    store.state.confirmedBroadcastedRooms.includes(hubId)
  );
  const [streamingTipDismissed, setStreamingTipDismissed] = useState(false);
  const [embeddedTipDismissed, setEmbeddedTipDismissed] = useState(false);
  const [onboardingTipId, setOnboardingTipId] = useState(null);

  const onSkipOnboarding = useCallback(
    () => {
      scene.systems.tips.skipTips();
    },
    [scene]
  );

  const onboardingImages = [
    {
      id: "tips.desktop.look",
      image: step1Desktop
    },
    {
      id: "tips.desktop.locomotion",
      image: step2Desktop
    },
    {
      id: "tips.desktop.turning",
      image: step3Desktop
    },
    {
      id: "tips.desktop.invite",
      image: step4Desktop
    }
    // {
    //   id: "tips.mobile.look",
    //   image: step1Mobile
    // },
    // {
    //   id: "tips.mobile.locomotion",
    //   image: step2Mobile
    // },
    // {
    //   id: "tips.mobile.invite",
    //   image: step4Desktop
    // }
  ];
  
  useEffect(
    () => {
      function onSceneTipChanged({ detail: tipId }) {
        setOnboardingTipId(tipId);
      }

      scene.addEventListener("tip-changed", onSceneTipChanged);

      setOnboardingTipId(scene.systems.tips.activeTip);
    },
    [scene]
  );

  const discordBridges = presences ? discordBridgesForPresences(presences) : [];
  const isBroadcasting = discordBridges.length > 0;

  // TODO: This only exists because we store local state in this component.
  // If we move tip state to a context then we can remove this and not render this component at all.
  if (hide) {
    return null;
  }

  if (inLobby) {
    if (lobbyTipDismissed) {
      return null;
    }

    return (
      <Tip onDismiss={() => setLobbyTipDismissed(true)} dismissLabel={<OkDismissLabel />}>
        <FormattedMessage
          id="tips.lobby"
          defaultMessage="Você está na Espera. Os outros usuários ainda não podem o ver ou o escutar."
        />
      </Tip>
    );
  } else if (inRoom) {
  
    // @VROOM
    // Temporariamente habilita as imagens de tutorial somente para desktop.
    // Remover .includes para contemplar mobile.
    if (onboardingTipId != null && onboardingTipId.includes("desktop")) {
      const img = onboardingImages.find(x => x.id === onboardingTipId);
      return (
        <TipOnboarding onDismiss={onSkipOnboarding} dismissLabel={<SkipDismissLabel />} image={img.image}>
          {intl.formatMessage(onboardingMessages[onboardingTipId], {
            appName: configs.translation("app-name"),
            moveKeys,
            turnLeftKey,
            turnRightKey
          })}
        </TipOnboarding>
      );
    }

    if (isStreaming && !streamingTipDismissed) {
      return (
        <Tip onDismiss={() => setStreamingTipDismissed(true)} dismissLabel={<OkDismissLabel />}>
          <FormattedMessage
            id="tips.streaming"
            defaultMessage="Transmitindo para a Espera. Para sair, desabilite o modo Stream no menu 'Mais'."
          />
        </Tip>
      );
    }

    if (isBroadcasting && !broadcastTipDismissed) {
      return (
        <Tip onDismiss={() => setBroadcastTipDismissed(true)} dismissLabel={<OkDismissLabel />}>
          <FormattedMessage
            id="tips.discord"
            defaultMessage="O Chat desta sala está sendo transmitindo no {broadcastTarget} através do Discord."
            values={{ broadcastTarget: discordBridges.map(channelName => "#" + channelName).join(", ") }}
          />
        </Tip>
      );
    }

    if ((isEmbedded || hasEmbedPresences(presences)) && !embeddedTipDismissed) {
      return (
        <Tip onDismiss={() => setEmbeddedTipDismissed(true)} dismissLabel={<OkDismissLabel />}>
          <FormattedMessage
            id="tips.embedded"
            defaultMessage="Esta sala é embedded, portanto pode estar visível para visitação em outros websites."
          />
        </Tip>
      );
    }

    return null;
  }

  return null;
}

TipContainer.propTypes = {
  hide: PropTypes.bool,
  inLobby: PropTypes.bool,
  inRoom: PropTypes.bool,
  isStreaming: PropTypes.bool,
  isEmbedded: PropTypes.bool,
  scene: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  hubId: PropTypes.string,
  presences: PropTypes.object
};
